<template>
    <div>
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>交易流水</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-select size="small" v-model="merchantid" filterable style="margin-right:10px;width:150px" clearable placeholder="选择商户" @change="GetStore" v-if="ismerchant != 1">
                                    <el-option v-for="item in merchants" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                                </el-select>
                                
                                <el-select v-model="tradetype" filterable style="margin-right:10px;width:150px" clearable placeholder="请选择消费类型" size="small">
                                    <el-option label="充值" value="1"></el-option>
                                    <el-option label="充值赠送" value="2"></el-option>
                                    <el-option label="续费" value="3"></el-option>
                                    <el-option label="下单" value="4"></el-option>
                                    <el-option label="退款" value="5"></el-option>
                                    <el-option label="其他" value="6"></el-option>
                                </el-select>
                                <el-select v-model="paytype" filterable style="margin-right:10px;width:150px" clearable placeholder="请选择支付方式" size="small">
                                    <el-option label="支付宝" value="1"></el-option>
                                    <el-option label="支付宝账户余额" value="2"></el-option>
                                    <el-option label="充值赠送" value= "3"></el-option>
                                    <el-option label="退款" value= "4"></el-option>
                                    <el-option label="核销码" value= "5"></el-option>
                                    <el-option label="微信" value="6"></el-option>
                                    <el-option label="微信账户余额" value="7"></el-option>
                                </el-select>
                                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right:10px;" size="small"></el-date-picker>
                                <el-input v-model="keyword"  placeholder="请输入订单号/交易流水号/用户手机" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px;margin-right:10px;" size="small"></el-input>
                                <el-button type="primary" size="small" @click="SelectStore" style="margin-right:10px;" v-if="ismerchant != 1">选择店铺</el-button>
                                <el-button type="primary" @click="init" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="primary"  style="margin-left:10px;" class="hidden-sm-and-down" size="small">
                                    <download-excel class="btn btn-default"                 :fetch="fetchData" :fields="json_fields"
                                        :before-generate="startDownload" :before-finish="finishDownload" worksheet="My Worksheet"
                                        type="csv" name="交易流水.csv">
                                        <span style="padding: 11px 20px; margin: 11px -20px">导出</span>
                                    </download-excel>
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
             <div class="content-main">
                 <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                >
                    <el-table-column label="交易流水号" prop="BillNo" width="220"></el-table-column>
                    <el-table-column label="订单号" prop="OrderUID" width="160">
                        <template #default="scope">
                            <span>{{scope.row.OrderUID?scope.row.OrderUID:"-"}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付时间" prop="AddDTime" min-width="100">
                        <template #default="scope">
                            <span>{{formatDate(scope.row.AddDTime*1000)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属商户/店铺" width="200">
                        <template #default="scope">
                            <span>{{scope.row.Merchant}}<br>{{scope.row.Store}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="消费类型">
                        <template #default="scope">
                            <span v-if="scope.row.TradeType == 1">充值</span>
                            <span v-if="scope.row.TradeType == 2">充值赠送</span>
                            <span v-if="scope.row.TradeType == 3">续费</span>
                            <span v-if="scope.row.TradeType == 4">下单</span>
                            <span v-if="scope.row.TradeType == 5">退款</span>
                            <span v-if="scope.row.TradeType == 6">其他</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付方式">
                        <template #default="scope">
                            <span v-if="scope.row.PayType == 1">支付宝</span>
                            <span v-if="scope.row.PayType == 2">支付宝账户余额</span>
                            <span v-if="scope.row.PayType == 3">充值赠送</span>
                            <span v-if="scope.row.PayType == 4">退款</span>
                            <span v-if="scope.row.PayType == 5">核销码</span>
                            <span v-if="scope.row.PayType == 6">微信</span>
                            <span v-if="scope.row.PayType == 7">微信账户余额</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="交易金额（元）" width="150px">
                        <template #default="scope">
                            <span v-if="scope.row.Source == 1" style="color:rgb(112, 182, 3)">+ {{scope.row.BillMoney/100}}</span>
                            <span v-if="scope.row.Source == 2" style="color:rgb(255, 20, 20)">- {{scope.row.BillMoney/100}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户账户余额（元）" width="150px">
                        <template #default="scope">
                            <span v-if="scope.row.CurCredit">{{scope.row.CurCredit/100}}</span>
                            <span v-else>0.00</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机" width="120px" prop="Mobile"></el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>

                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
             </div>

             
        </el-card>
    </div>
    <el-dialog v-model="selstore" title="选择店铺" center>
            <div class="merchant-block">
                <el-transfer v-model="storeid"
                    filterable
                    filter-placeholder="请输入店铺名称"
                    :data='selstorelist'
                    :titles='transfertitle'>
                </el-transfer>
                <div class="bottom">
                    <el-button type="primary" size="small" @click="ConfirmSel">确定</el-button>
                    <el-button size="small" @click="Back">取消</el-button>
                </div>
            </div>
    </el-dialog>
</template>
<style scoped>
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
import JsonExcel from "vue-json-excel3";
import { ElMessage } from "element-plus";
export default {
    components: {
		downloadExcel: JsonExcel,
	},
    data(){
        return {
            merchants:[],
            selstore:false,
            storeid:[],
            selstorelist:[],
            storelist:[],
            transfertitle:['店铺列表','已选店铺'],
            merchantid:'',
            curpage:1,
            pagesize:10,
            totalcount:'',
            tabledata:[],
            range:'',
            keyword:'',
            shortcuts: [
                {
                    text: '最近一周',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                    },
                },
                {
                    text: '最近一个月',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                    },
                },
                {
                    text: '最近三个月',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                    },
                },
            ],
            json_fields: {
				交易流水号: "BillNo",
				订单编号: "OrderUID",
				支付时间: "AddDTime",
				所属商户: "Merchant",
                消费类型: {
					field: "TradeType",
					callback: (value) => {
						if (value == 1) {
							return `充值`;
						}
						if (value == 2) {
							return `充值赠送`;
						}
						if (value == 3) {
							return `续费`;
						}
                        if (value == 4) {
							return `下单`;
						}
						if (value == 5) {
							return `退款`;
						}
						if (value == 6) {
							return `其他`;
						}
					},
				},
                支付方式: {
					field: "PayType",
					callback: (value) => {
						if (value == 1) {
							return `支付宝`;
						}
						if (value == 2) {
							return `支付宝账户余额`;
						}
						if (value == 3) {
							return `充值赠送`;
						}
                        if (value == 4) {
							return `退款`;
						}
						if (value == 5) {
							return `核销码`;
						}
						if (value == 6) {
							return `微信`;
						}
                        if (value == 7) {
							return `微信账户余额`;
						}
					},
				},
				交易金额: "BillMoney",
				商户账户余额: "CurCredit",
                用户手机号:"Mobile"
			},
            orderuid:'',
            tradetype:'',
            paytype:'',
            load:''
        }
    },
    methods:{
        async fetchData() {
			const response = await this.axios.get(constant.exportbill, {
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					keyword:this.keyword,
                    merchantid:this.merchantid,
                    type:this.type,
                    range:JSON.stringify(this.range),
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    tradetype:this.tradetype
				},
			});
			console.log(response.data);
			if (response.data.length == 0) {
				ElMessage({
					type: "warning",
					message: "暂无数据可下载",
					center: true,
				});
				return false;
			}
			return response.data;
		},
		startDownload() {
			ElMessage({
				type: "warning",
				message: "开始下载",
				center: true,
			});
		},
		finishDownload() {
			ElMessage({
				type: "success",
				message: "下载完成",
				center: true,
			});
		},
        GetStore(merchantid){
            this.axios.get(constant.allstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.storelist = response.data;
            })
        },
        ConfirmSel(){
            console.log(this.storeid);
            this.selstore = false;
        },
        Back(){
            this.selstore = false;
        },
        SelectStore(){
            this.selstore = true;
            this.selstorelist = [];
            for(let i = 0;i<this.storelist.length;i++){
                let obj = new Object();
                obj.label = this.storelist[i]['Store'];
                obj.key = this.storelist[i]['ID'];
                this.selstorelist.push(obj);
            }
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },

        formatDate(date){
                var datetime = new Date(date);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2);
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },

        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },

        init(){
            this.load = true;
            this.axios.get(constant.billlist,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    tradetype: this.tradetype,
                    paytype:this.paytype,
                    keyword: this.keyword,
                    range:JSON.stringify(this.range),
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:this.merchantid,
                    storeid:JSON.stringify(this.storeid),
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
                this.load = false;
            });
        },

    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.keyword = this.$route.query.keyword;
        this.axios.get(constant.allstore,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log(response.data);
            this.storelist = response.data;
        });

        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            },

        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>